import { useState } from "react";
import { Text, Center } from "@chakra-ui/layout";
import {FormControl, FormLabel, FormHelperText, 
  FormErrorMessage, Input, Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton 
} from "@chakra-ui/react";
//import { useForm } from "react-hook-form";
import {Properties} from '../types'
import { useParams } from "react-router-dom";
import { portalApiPost, portalApiPut } from "../api/PortalAPI";
import { portalApiConfig } from "../authConfig";
import { controlType } from "../constants";
import {DatePicker} from "../components/Controls";
import validator from "validator";
import { useMsal } from "@azure/msal-react";


interface Props<ObjectType> {
  object: ObjectType;
  properties:Properties<ObjectType>[];
  mode: string; 
  isOpen: boolean;
  onClose: any;
  fetchData: () => void;
  accessToken: string;
}

export function DataForm<ObjectType>(
  {object, properties, mode, isOpen, onClose, fetchData, accessToken}: Props<ObjectType>,
) {
    //const [input, setInput] = useState({})

    //const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
      //  const {
        //  target: { name, value }
        //} = event;
        //setInput({ ...input, [name]: value });
      //}; 
      
      //const handlesubmit  = (event: HTMLFormElement<HTMLFormElement>) => {
        //const formData = new FormData(event);
        //event.preventDefault();
        //for (let [key, value] of formData.entries()) {
          //console.log(key, value);
        //}
    //};



    const [formData, updateFormData] = useState(object);
    const isError = false;
    const [errors, setErrors] = useState(properties); 
    const { accounts } = useMsal();   
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    //const errors:any = {};
    const { id } = useParams<{ id: string }>();
    var primaryKey = properties.filter(prop => prop.isprimarykey);      
    var rowid = object[primaryKey[0].key];

    const handleChange = (e: { target: { name: any; type: any; value: any; }; }) => {
      updateFormData({
        ...formData,
  
        // Trimming any whitespace
        [e.target.name]: e.target.value
      });
      //setErrors({
        //...errors,
        //[e.target.name]: 'prop-test-chang'
      //});
      console.log(formData);
    };

    const ondaychange = (name: any, value: any) => {
      updateFormData({
        ...formData,
  
        // Trimming any whitespace
        [name]: value
      });
    }
    const validate = (data: any) => {
      
      var status = true;
      // perform more checks
      
      properties.map((property, index) => {
               //Object.entries(errors).forEach(
          //([key, value]) => console.log('te'+ key, value)
        //)
        property.errorMessage = '';
        if(property.isprimarykey === false && property.isnullable===false 
          && !data[property.key])
        {       
           
          property.errorMessage = '*required';
          status=false;   
          //console.log('null'+data[property.key] + status);         
        }

        if(property.datatype === 'number' && (data[property.key] && isNaN(data[property.key])))
        {
          property.errorMessage = 'invalid number';
          status=false;  
        }
        if(property.isemail && !validator.isEmail(data[property.key]))
        {
          property.errorMessage = 'invalid email';          
          status=false; 
        }
        if(property.isphonenumber && !validator.isMobilePhone(String(data[property.key])))
        {
          property.errorMessage = 'invalid phone number';          
          status=false; 
        }
        console.log(status);
        return status;
      });

      setErrors({
        ...errors,
        ...properties
      });
      //Object.entries(errors).forEach(
        //  ([key, value]) => console.log('te'+ key, value)
        //)
      return status;
    };

    const onModalClose = (e: { preventDefault: () => void; }) => {
      e.preventDefault();
      setErrorMessage('');
      onClose();
    }
  
    const onSubmit = (e: { preventDefault: () => void; }) => {
      e.preventDefault();
      const data:any = formData;
      delete formData[primaryKey[0].key];

      if(mode === 'Add')
      {
      properties.filter(prop => prop.isautofilluseradd).forEach((ele) => {
        data[ele.key] = accounts[0].username;
      })

      properties.filter(prop => prop.isautofilldateadd).forEach((ele) => {
        data[ele.key] = new Date().toLocaleDateString();
      })
    }

    if(mode === 'edit')
      {
      properties.filter(prop => prop.isautofilluseredit).forEach((ele) => {
        data[ele.key] = accounts[0].username;
        console.log(data[ele.key]);
        console.log('useredit');
      })

      properties.filter(prop => prop.isautofilldateedit).forEach((ele) => {
        data[ele.key] = new Date().toLocaleDateString();
        console.log(data[ele.key]);
        console.log('userdate');
      })
    }

      console.log(data);

      if (!validate(formData))
      {        
        return;
      }
      
    if (!accessToken) {

      console.log('Edit error token')
      return;
    }

      if(mode === 'Add')
      {        
        portalApiPost(
          `${portalApiConfig.endpoints.addMetadataTable}/${id}`,
          accessToken,data
        )
          .then((res) => {
            if(res.status === 200)
            {
              setErrorMessage('');
              console.log('added successfully')
              fetchData();
              onClose();
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              console.log('unique key exist');              
              properties.filter(prop => prop.isuniquekey).map((property, index) => {
                return property.errorMessage = String(property.key) + ' already exist';
              });
              setErrors({
                ...errors,
                ...properties
              });
            }
            else if (error.response.status === 409)
            {             
              setErrorMessage(error.response.data);
              console.log(error.response.data);
            }
          });
      }
      else
      {
        portalApiPut(
          `${portalApiConfig.endpoints.updateMetadataTable}/${id}/${String(primaryKey[0].key)}/${rowid}`,
          accessToken,data
        )
          .then((res) => {
            if(res.status === 200)
            {
              setErrorMessage('');
              console.log('updated successfully');
              fetchData();
              onClose();              
            }            
          })
          //.catch((e: Error) => console.error(e));
          .catch((error) => {
            if (error.response.status === 400) {
              console.log('unique key exist');              
              properties.filter(prop => prop.isuniquekey).map((property, index) => {
                return property.errorMessage = String(property.key) + ' already exist';
              });
              setErrors({
                ...errors,
                ...properties
              });
            }
            else if (error.response.status === 409)
            {             
              setErrorMessage(error.response.data);
              console.log(error.response.data);
            }
          });
      }
      // ... submit to API or something
      
      
    };

  
  //input === ''
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{mode}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
        <FormControl key="formControl" isInvalid={isError}>          
            {properties.map((property, index) => {
              if(!property.isprimarykey && !property.isautofilldateadd
                && !property.isautofilluseradd && !property.isautofilldateedit
                && !property.isautofilluseredit)
              {                  
                if (property.datatype === controlType.DATE_TIME)
                {                  
                  return(
                  <>
                  <FormLabel key={"key-"+index} id={String(property.key)+'lbl'} htmlFor={String(property.key)}>{property.title}</FormLabel>                
                  <DatePicker key={"keyDp-"+index}
                  name={String(property.key)} handleChange={ondaychange}
                  value={String(object[property.key]?object[property.key]:'')}                
                />
                </>
                  );
                }                
                else {
                  return (
                <>
                <FormLabel key={"key-"+index} id={String(property.key)+'lbl'} htmlFor={String(property.key)}>{property.title}</FormLabel>                
                <Input key={"keyInp-"+index} id={'txt'+String(property.key)} name={String(property.key)}                 
                 defaultValue={String(object[property.key]?object[property.key]:'')}
                 onChange={handleChange}                 
                 />                 
                 {property.errorMessage?<Text key={"keyTxt-"+index} color="expanded.punch">{property.errorMessage}</Text>:<></>}
                </>
                  );
                }
              }
              else
              {
                return(<></>);
              }
              
              //return null;
})}
         {!isError ? (
        <FormHelperText>
          
        </FormHelperText>
      ) : (
        <FormErrorMessage>* fields are required.</FormErrorMessage>
      )}       
    </FormControl>
    {errorMessage ? (
        <Center color="expanded.punch">{errorMessage}</Center>
      ) : <></>}
    </ModalBody>   
    <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onModalClose}>
              Close
            </Button>
            <Button variant='ghost' onClick={onSubmit}>Submit</Button>
            </ModalFooter>       
        </ModalContent>
      </Modal>
    );
}