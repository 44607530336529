import React from "react";
import { Button } from "@chakra-ui/button";

type StyledButtonProps = {
  children: React.ReactNode;
  [x: string]: any;
};

export const StyledButton = ({ children, ...rest }: StyledButtonProps) => {
  return (
    <Button bg="brand.teal" _hover={{ bg: "brand.hoverteal" }} {...rest}>
      {children}
    </Button>
  );
};
