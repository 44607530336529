const tenantId = process.env.REACT_APP_TENANT_ID;

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_PORTAL_API_CLIENT_ID ?? "",
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: "/callback",
    navigateToLoginRequestUrl: false,
    postlogoutRedirectUrl: "/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: [
    "openid",
    "profile",
    `api://${msalConfig.auth.clientId}/api.execute`,
  ],
};

export const portalApiConfig = {
  host: process.env.REACT_APP_API_HOST,
  endpoints: {
    getTemplate: "getTemplates",
    getMetadataTable: "getmetadatatable",
    getSchema: "Getschema",
    deleteMetadataTable: "deletemetadatatable",
    addMetadataTable: "addmetadatatable",
    updateMetadataTable: "updatemetadatatable",
    createportaldatasetrequest: "createportaldatasetrequest",
    getdatasetrequeststatus: "getdatasetrequeststatus",
    downloaddataset: "downloaddataset",
    completedownload: "completedownload",
    downloadAttachment: "downloadattachment",
    cancelrequest: "cancelrequest",
  },
  subscriptionKey: process.env.REACT_APP_APIM_PORTAL_SUBSCRIPTION_KEY,
  trace: process.env.REACT_APP_APIM_PORTAL_TRACE,
};