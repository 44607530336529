import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "./StyledButton";
import { loginRequest } from "../authConfig";

type SignInOutButtonProps = {
  isSignIn: boolean;
};

export const SignInOutButton = ({ isSignIn }: SignInOutButtonProps) => {
  const { t } = useTranslation("common");
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(process.env.REACT_APP_PORTAL_API_CLIENT_ID);
      console.error(e);
    });
  };

  const handleLogout = () => {
    instance.logoutRedirect().catch((e: Error) => {
      console.error(e);
    });
  };

  return (
    <StyledButton onClick={() => (isSignIn ? handleLogin() : handleLogout())}>
      {isSignIn ? t("Sign in") : t("Sign Out")}
    </StyledButton>
  );
};
