import { Box, Text, VStack, OrderedList, ListItem } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { HStack, Stack } from "@chakra-ui/react";

const TermsOfUsePage = () => {
  const { t } = useTranslation("common");

  return (
    <Box px="10">
      <VStack py="4">
        <Text>{t("termsOfUse.heading")}</Text>
        <Text>{t("Terms of Use")}</Text>
        <Text textDecoration="underline">{t("termsOfUse.important")}</Text>
      </VStack>
      <Text pb="4">{t("termsOfUse.description")}</Text>
      <OrderedList spacing="4" pb="4">
        <ListItem>
          <Text fontWeight="bold">{t("termsOfUse.license")}</Text>
          <HStack pt="4">
            <Text>a. </Text>
            <Text textDecoration="underline">
              {t("termsOfUse.grantOfLicense")}
            </Text>
          </HStack>
          <Text>{t("termsOfUse.grantOfLicense_a")}</Text>
          <HStack pt="4">
            <Text>b. </Text>
            <Text textDecoration="underline">
              {t("termsOfUse.limitations")}
            </Text>
            <Text>{t("termsOfUse.except")}</Text>
          </HStack>
          <Stack pl="4" pt="4" spacing="4">
            <Text>{t("termsOfUse.limitations_i")}</Text>
            <Text>{t("termsOfUse.limitations_ii")}</Text>
            <Text>{t("termsOfUse.limitations_iii")}</Text>
            <Text>{t("termsOfUse.limitations_iv")}</Text>
            <Text>{t("termsOfUse.limitations_v")}</Text>
            <Text>{t("termsOfUse.limitations_vi")}</Text>
            <Text>{t("termsOfUse.limitations_vii")}</Text>
            <Text>{t("termsOfUse.limitations_viii")}</Text>
            <Text>{t("termsOfUse.limitations_ix")}</Text>
          </Stack>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.changesToSite")}
          </Text>
          <Text>{t("termsOfUse.changesToSite_description")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.noMaintenance")}
          </Text>
          <Text>{t("termsOfUse.noMaintenance_description")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.proprietary")}
          </Text>
          <Text>{t("termsOfUse.proprietary_description")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.feedback")}
          </Text>
          <Text>{t("termsOfUse.feedback_description")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.access")}
          </Text>
          <Text pb="4">{t("termsOfUse.access_a")}</Text>
          <Text>{t("termsOfUse.access_b")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.confidential")}
          </Text>
          <Stack spacing="4">
            <Text>{t("termsOfUse.confidential_a")}</Text>
            <Text>{t("termsOfUse.confidential_b")}</Text>
            <Text pl="4">{t("termsOfUse.confidential_b_i")}</Text>
            <Text pl="4">{t("termsOfUse.confidential_b_ii")}</Text>
            <Text pl="4">{t("termsOfUse.confidential_b_iii")}</Text>
            <Text pl="4">{t("termsOfUse.confidential_b_iv")}</Text>
            <Text pl="4">{t("termsOfUse.confidential_b_v")}</Text>
            <Text>{t("termsOfUse.confidential_c")}</Text>
          </Stack>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.forums")}
          </Text>
          <Stack spacing="4">
            <Text>{t("termsOfUse.forums_a")}</Text>
            <Text>{t("termsOfUse.forums_b")}</Text>
            <Text pl="4">{t("termsOfUse.forums_b_i")}</Text>
            <Text pl="4">{t("termsOfUse.forums_b_ii")}</Text>
            <Text pl="4">{t("termsOfUse.forums_b_iii")}</Text>
            <Text pl="4">{t("termsOfUse.forums_b_iv")}</Text>
            <Text pl="4">{t("termsOfUse.forums_b_v")}</Text>
            <Text pl="4">{t("termsOfUse.forums_b_vi")}</Text>
            <Text pl="4">{t("termsOfUse.forums_b_vii")}</Text>
            <Text>{t("termsOfUse.forums_c")}</Text>
          </Stack>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.termination")}
          </Text>
          <Text>{t("termsOfUse.termination_description")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.indemnification")}
          </Text>
          <Text>{t("termsOfUse.indemnification_description")}</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.exclusion")}
          </Text>
          <Stack spacing="4">
            <Box>
              <Box as="span">{t("termsOfUse.A")} </Box>
              <Box as="span" textDecoration="underline">
                {t("termsOfUse.exclusion_of_warranty")}
              </Box>
              <Box as="span"> {t("termsOfUse.exclusion_a")} </Box>
            </Box>
            <Box>
              <Box as="span">{t("termsOfUse.B")} </Box>
              <Box as="span" textDecoration="underline">
                {t("termsOfUse.limitation")}
              </Box>
              <Box as="span"> {t("termsOfUse.exclusion_b")} </Box>
            </Box>
            <Box>
              <Box as="span">{t("termsOfUse.c")} </Box>
              <Box as="span" textDecoration="underline">
                {t("termsOfUse.links")}
              </Box>
              <Box as="span"> {t("termsOfUse.exclusion_c")} </Box>
            </Box>
          </Stack>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" pb="4">
            {t("termsOfUse.general")}
          </Text>
          <Stack spacing="4">
            <Text whiteSpace="pre-line">{t("termsOfUse.general_a")}</Text>
            <Text>{t("termsOfUse.general_b")}</Text>
            <Text>{t("termsOfUse.general_c")}</Text>
            <Text>{t("termsOfUse.general_d")}</Text>
            <Text>{t("termsOfUse.general_e")}</Text>
            <Text>{t("termsOfUse.general_f")}</Text>
            <Text>{t("termsOfUse.general_g")}</Text>
            <Text>{t("termsOfUse.general_h")}</Text>
          </Stack>
        </ListItem>
      </OrderedList>
      <Text py="8">{t("termsOfUse.copyright")}</Text>
    </Box>
  );
};

export default TermsOfUsePage;
