import { useEffect, useState } from "react";
import { Box, Center, Heading } from "@chakra-ui/layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import { CircularProgress } from "@chakra-ui/progress";
import { useMsal } from "@azure/msal-react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/accordion";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { portalApiGet, requestAccessToken } from "../api/PortalAPI";
import { portalApiConfig } from "../authConfig";
import {  ErrorResponse, permission, Properties } from "../types";
import { DataTable } from "../components/DataTable";
import { Button, useDisclosure, FormLabel, Input } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { DataForm } from "../components/DataForm";

const MetaDataPage = () => {
  const { t } = useTranslation("common");
  const { id } = useParams<{ id: string }>();
  const [metaDataDetails, setMetaDataDetails] = useState<any[] | null>(null);
  const [initialmetaDataDetails, setInitialtMetaDataDetails] = useState<any[] | null>(null);
  const [schema, setSchema] = useState<Properties<any>[] | undefined>();
  const [permissions, setPermission] = useState<permission[] | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    
    requestAccessToken(instance, accounts, setAccessToken);

    if (!accessToken) {

      console.log('error token')
      return;
    }

    portalApiGet(
      `${portalApiConfig.endpoints.getSchema}/${id}`,
      accessToken
    )
      .then((res) => {
        const json = res.data;
        if (json) {          
          setSchema(res.data.schemas);
          setPermission(res.data.permissions);
        } else if (json as ErrorResponse) {
          console.log(json);
          setErrorMessage((json as ErrorResponse).message);
        }
      })
      .catch((e: Error) => console.error(e));

    portalApiGet(
      `${portalApiConfig.endpoints.getMetadataTable}/${id}`,
      accessToken
    )
      .then((res) => {
        const json = res.data;
        if (json) {
          setMetaDataDetails(res.data);
          setInitialtMetaDataDetails(res.data);
        } else if (json as ErrorResponse) {
          console.log(json);
          setErrorMessage((json as ErrorResponse).message);
        }
      })
      .catch((e: Error) => console.error(e));
  }, [accessToken, instance, accounts, id]);

  const fetchData = () => {

    requestAccessToken(instance, accounts, setAccessToken);

    if (!accessToken) {

      console.log('error token')
      return;
    }

    portalApiGet(
      `${portalApiConfig.endpoints.getMetadataTable}/${id}`,
      accessToken
    )
      .then((res) => {
        const json = res.data;
        if (json) {
          setMetaDataDetails(res.data);
          setInitialtMetaDataDetails(res.data);
          console.log('fetched data');
        } else if (json as ErrorResponse) {
          console.log(json);
          setErrorMessage((json as ErrorResponse).message);
        }
      })
      .catch((e: Error) => console.error(e));

  }

  const search = (event: {
    preventDefault: () => void; target: { value: any; }; 
}) => {
    event.preventDefault();
    let input = event.target.value;

    if(input.length === 0)
    {
      setMetaDataDetails(initialmetaDataDetails);
    }

    let result : any[] | undefined = [];

    result = initialmetaDataDetails?.filter((data) => {
      return Object.keys(data).some((key)=>{
        return String(data[key]).toLowerCase().trim().includes(input);
      })
    });

    if(result !== undefined && result.length > 0)
    {
        setMetaDataDetails(result);
    }
    else
    {
      setMetaDataDetails([]);      
    }
  }

  return (
    <Box px={10} pt={5}>
      <Breadcrumb separator=">">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/templates">
            {t("home")}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/templates">
            {t("metadataTemplate")}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem fontWeight="bold">
          <BreadcrumbLink isCurrentPage>{id}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      {permissions && permissions.some(e => e.PermissionName === 'Add') ? (
      <Button leftIcon={<AddIcon/>} onClick={onOpen}></Button> 
      ): (
        <></>
      )}       
      {metaDataDetails && schema ?  (         
        <Box pt="4">
          <DataForm key={"dataFormNewKey"} object={{}} properties= {schema} mode='Add' 
         isOpen={isOpen} onClose={onClose} accessToken={accessToken ?? ""}
         fetchData={fetchData}/>
          <Heading color="essentials.sky" size="lg" pb="4">            
          </Heading>
          <Accordion defaultIndex={[0]} allowMultiple allowToggle>            
            <AccordionItem>
              <Heading color="essentials.sky">
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {t("Data")}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Heading>
              <AccordionPanel pb="4"> 
              <FormLabel key={"Searchkey"} id={'lblSearch'} htmlFor={'Search'}>{"Search"}</FormLabel>                
                <Input key={"keySearchInp-"} id={'txtSearch'} name={'nmSearch'}
                 onChange={search}                 
                 />                  
              <DataTable objects={metaDataDetails} permissions = {permissions} properties= {schema} fetchData = {fetchData} 
              accessToken={accessToken ?? ""}/>                
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      ) : errorMessage ? (
        <Center color="expanded.punch">{errorMessage}</Center>
      ) : (
        <Center pt="4">
          <CircularProgress isIndeterminate color="essentials.sky" />
        </Center>
      )}
    </Box>
  );
};

export default MetaDataPage;
