import { Image, Flex, Spacer, Text, Stack, HStack } from "@chakra-ui/react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { SignInOutButton } from "./SignInOutButton";

import logo from "../resources/Moneris_Logo_BIL_White.png";

const Header = () => {
  const { t } = useTranslation("common");
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <Flex px={10} py={4} backgroundColor="brand.darkblue">
      <Image
        htmlWidth="143px"
        htmlHeight="48px"
        src={logo}
        alt={t("Moneris")}
      />
      <Spacer />
      {isAuthenticated ? (
        <HStack>
          <Stack spacing="0" pr="4">
            <Text color="white">{accounts[0].name}</Text>
            <Text color="white">{accounts[0].username}</Text>
          </Stack>
          <SignInOutButton isSignIn={false} />
        </HStack>
      ) : (
        <SignInOutButton isSignIn={true} />
      )}
    </Flex>
  );
};

export default Header;
