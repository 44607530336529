import { PropsWithChildren } from "react";
import { Table, Tbody, Box } from "@chakra-ui/react";
import {DataHead} from './DataHead'
import {DataRow} from './DataRow'
import { permission } from "../types";

interface Props<ObjectType> {
    objects: ObjectType[];
    properties: {
        key: keyof ObjectType,
        title: string,
        isprimarykey: boolean|undefined,
        isnullable: boolean,
        datatype: string,
        errorMessage: string|undefined,
        isuniquekey: boolean,
        ishide: boolean,
        isautofilldateadd: boolean,
        isautofilluseradd: boolean,
        isautofilldateedit: boolean,
        isautofilluseredit: boolean,
        isemail: boolean,
        isphonenumber: boolean    
    }[];
    fetchData: () => void;  
    accessToken: string;  
    permissions: permission[] | undefined;
}

export function DataTable<ObjectType>(
    {objects, properties, fetchData, accessToken, permissions}: PropsWithChildren<Props<ObjectType>>,
) {  
     
    return (
        <Box overflowX="auto">
        <Table size="sm">
            <DataHead properties={properties}/>
            <Tbody>
                {
                    objects.map((object, index) => (
                        <DataRow key={"key-"+index} object={object} permissions={permissions} properties={properties} 
                        accessToken={accessToken} fetchData={fetchData}/>
                    ))
                }
            </Tbody>
        </Table>
        </Box>
    );
}