import { FunctionComponent } from 'react'
import { Thead, Tr, Th } from "@chakra-ui/react";
import { Properties } from '../types';

interface Props {    
    properties: Properties<any>[]; 
}

export const DataHead: FunctionComponent<Props> = ({
    properties,
}
)=> {
    return (
        <Thead>
            <Tr>
                <Th key='icon'></Th>
                <Th key='icon'></Th>
                {
                    properties.map(property => (
                        !property.ishide?
                        <Th key={String(property.key)}>{property.title}</Th>:<></>
                    ))
                }
            </Tr>
        </Thead>
    )
}