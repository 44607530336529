import { IPublicClientApplication } from "@azure/msal-browser";
import { portalApiConfig, loginRequest } from "../authConfig";
import axios, { AxiosRequestConfig } from "axios";

const headers = (accessToken: string) => {
  return {
    Authorization: `Bearer ${accessToken}`,
    "ocp-apim-subscription-key": portalApiConfig.subscriptionKey,
    "ocp-apim-trace": portalApiConfig.trace,
  };
};

export const portalApiGetBlob = async (
  endpoint: string,
  accessToken: string
) => {
  const config: AxiosRequestConfig = {
    headers: headers(accessToken),
    responseType: "blob",
  };
  return await axios.get(`${portalApiConfig.host}/${endpoint}`, config);
};

export const portalApiGet = async (endpoint: string, accessToken: string) => {
  const config: AxiosRequestConfig = {
    headers: headers(accessToken),
  };
  return await axios.get(`${portalApiConfig.host}/${endpoint}`, config);
};

export const portalApiPost = async (
  endpoint: string,
  accessToken: string,
  body: {}
) => {
  const config: AxiosRequestConfig = {
    headers: headers(accessToken),
  };
  return await axios.post(`${portalApiConfig.host}/${endpoint}`, body, config);
};

export const portalApiPut = async (endpoint: string, accessToken: string, body: {}) => {
  const config: AxiosRequestConfig = {
    headers: headers(accessToken),
  };
  return await axios.put(`${portalApiConfig.host}/${endpoint}`, body, config);
};

export const portalApiDelete = async (endpoint: string, accessToken: string) => {
  const config: AxiosRequestConfig = {
    headers: headers(accessToken),
  };
  return await axios.delete(`${portalApiConfig.host}/${endpoint}`, config);
};

export const requestAccessToken = (
  instance: IPublicClientApplication,
  accounts: any,
  setAccessToken: any
) => {
  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  // Silently acquires an access token which is then attached to a request
  instance
    .acquireTokenSilent(request)
    .then((response: any) => {
      setAccessToken(response.accessToken);
      console.log('got the token')
    })
    .catch((e: Error) => {
      instance.acquireTokenRedirect(request).then((response: any) => {
        setAccessToken(response.accessToken);
      });
      console.error(e);
    });
};
