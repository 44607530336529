// chakra-ui-autocomplete used for muliselect dropdown.
// See https://www.npmjs.com/package/chakra-ui-autocomplete for more details

// react-day-picker used for date picker.
// See https://react-day-picker.js.org/ for more details

import { Box } from "@chakra-ui/layout";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import { getLocaleDateString } from "../utils/getLocaleDateString";
import theme from "../theme";

import "react-day-picker/lib/style.css";
import "../styles/selectable.css";

type ControlProps = {
  name: any;
  handleChange: (name: any, value: any) => void;
  value: any;
};

export const DatePicker = ({
  name,  
  handleChange,
  value
}: ControlProps) => {
  
  const handleDayChange = (day: Date) => {
    //onchange(e: {target: {name, 'string', day}});
    //console.log(day);
    handleChange(name, day);
  };  

  const modifiers = {
    value,
  };

  const modifiersStyles = {
    selectedDay: {
      backgroundColor: theme.colors.tints.fuse,
      color: "black",
    },
  };

  const inputStyle = {
    border: "solid",
    borderWidth: "1px",
    borderColor: "lightgrey",
    borderRadius: "5px",
    padding: "6px",
    paddingLeft: "10px",
    outlineColor: theme.colors.tints.fuse,
  };

  const format = getLocaleDateString();

  return (
    <Box>
      <DayPickerInput
        dayPickerProps={{
          modifiers,
          modifiersStyles,
          selectedDays: value,
        }}
        parseDate={MomentLocaleUtils.parseDate}
        formatDate={MomentLocaleUtils.formatDate}
        inputProps={{ style: inputStyle, name: name}}
        onDayChange={handleDayChange}
        format={format}
        placeholder={format}
        value={value}        
      />
    </Box>
  );
};

