import React from 'react';
import {Button} from "@chakra-ui/react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
  } from '@chakra-ui/react';

  interface Props { title: any; children: any;  isOpen: boolean, onClose: any, onConfirmDelete: any   }

  export function ConfirmDialog({ title, children, isOpen, onClose, onConfirmDelete  }: Props)  {

  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef = {cancelRef}
    ><AlertDialogOverlay>
          <AlertDialogContent>
             <AlertDialogHeader fontSize='lg' fontWeight='bold' id="confirm-dialog">{title}</AlertDialogHeader>
      <AlertDialogBody>{children}</AlertDialogBody>
      <AlertDialogFooter>
        <Button                    
          onClick={onClose}
          ref = {cancelRef}
        >Cancel</Button>
        <Button
          colorScheme='red'
          onClick={onConfirmDelete}
          ml={3}
        >Delete</Button>
      </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>
  );
};
