import {Props} from '../types'
import {Tr, Td, Button, 
    useDisclosure
    } from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { DataForm } from "../components/DataForm";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { useParams } from "react-router-dom";
import { portalApiDelete } from "../api/PortalAPI";
import { portalApiConfig } from "../authConfig";

export function DataRow<ObjectType>(
    {object, properties, fetchData, accessToken, permissions}: Props<ObjectType>
){
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { id } = useParams<{ id: string }>();
    const { isOpen: isDeleteOpen , onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();    
    var primaryKey = properties.filter(prop => prop.isprimarykey);      
    var rowid = object[primaryKey[0].key];
    
  // Handle the actual deletion of the item
  const handleDelete = () => {
        console.log('delete called' + rowid );
        
        if (!accessToken) {
          console.log('Delete error token')
          return;
        }
        
        portalApiDelete(
          `${portalApiConfig.endpoints.deleteMetadataTable}/${id}/${String(primaryKey[0].key)}/${rowid}`,
          accessToken
        )
          .then((res) => {
            if(res.status === 200)
            {
              console.log('deleted successfully');
              fetchData();
            }
          })
          .catch((e: Error) => console.error(e));
        onDeleteClose();
  };
    return (
        <>
        <Tr key="keyTr">
          <Td key='icon'> 
          {permissions && permissions.some(e => e.PermissionName === 'Edit') ? (
            <>
            <Button leftIcon={<EditIcon/>} onClick={onOpen} size='sm'></Button>            
            </>
            ) : (
              <></>
            )}
          </Td>
            <Td key='icon'>
            {/*<ButtonGroup size='sm' isAttached variant='outline' fontSize='sm' letterSpacing='tighter'
            lineHeight='normal' wordBreak='truncate' as='button' >*/}
            
            
            {permissions && permissions.some(e => e.PermissionName === 'Delete') ? (
            <Button leftIcon={<DeleteIcon/>} onClick={onDeleteOpen} size='sm'></Button>
            ) : (
              <></>
            )}
            {/*</ButtonGroup>*/}
            
            </Td>
            {
                properties.map(property => (
                  !property.ishide?
                    <Td key={String(property.key)}>{object[property.key]}</Td>:<></>
                ))
            }
        </Tr>
        <ConfirmDialog title="Delete" onConfirmDelete={handleDelete} isOpen={isDeleteOpen}
        onClose={onDeleteClose}>Are you sure to delete?</ConfirmDialog>           
           <DataForm key={"dataFormKey"} object={object} properties= {properties} mode='edit'
           isOpen={isOpen} onClose={onClose} fetchData={fetchData} accessToken={accessToken} />
        </>
    );
}