// Theming handled by chakra-ui.
// See https://chakra-ui.com/docs/theming/theme for more details

import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    essentials: {
      sky: "#00b6c9",
      sunrise: "#ffb600",
      sunset: "#fc4c02",
      midnight: "#051d33",
    },
    tints: {
      fuse: "#92d2e7",
      blend: "#c8e6f1",
      meld: "#707272",
      cement: "#54575a",
    },
    brand: {
      darkblue: "#1b3b58",
      teal: "#6dc7be",
      hoverteal: "#43b1a6",
      hovergray: "#c9c9c9",
      hoverpunch: "#FF3347",
    },
    expanded: {
      punch: "#FF5869",
      zest: "#FFF203",
      lush: "#93C90F",
      tide: "#1DA897",
      deep: "#00677F",
    },
  },
  fonts: {
    body: "barlow light, system-ui, sans-serif",
    heading: "AvenirLTPro-Black",
  },
});

export default theme;
