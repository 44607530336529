// More Info
// @azure/msal-react used for authentication.
// See https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react for more details

// react-router-dom used for navigation.
// See https://reactrouter.com/web/guides/quick-start for more details

// swagger-ui-react used to integrate API docs.
// See https://www.npmjs.com/package/swagger-ui-react for more details

import { Suspense, lazy } from "react";
import { Box, Flex } from "@chakra-ui/layout";
import { Switch, Route, Redirect } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import "swagger-ui-react/swagger-ui.css";
import { useTranslation } from "react-i18next";
import MetaDataPage from "./pages/MetaDataPage";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import LoginPage from "./pages/LoginPage";
// Code Splitting. See https://reactjs.org/docs/code-splitting.html#route-based-code-splitting for more details
const TemplatePage = lazy(() => import("./pages/TemplatePage"));


export default function App() {
  const { t } = useTranslation("common");
  return (
    <Flex h="100vh" margin="0" direction="column">
      <Header />
      <Suspense
        fallback={
          <Box px={10} pt={5}>
            {t("Loading...")}
          </Box>
        }
      >          
        <Switch>
        <Route exact path="/callback">
          <AuthenticatedTemplate>
              <Redirect to="/templates" />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
              <Redirect to="/" />
            </UnauthenticatedTemplate>
          </Route>
          <Route exact path="/templates">
          <AuthenticatedTemplate>
              <TemplatePage />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
              <Redirect to="/" />
            </UnauthenticatedTemplate>
          </Route>
          <Route exact path="/templates/:id">
          <AuthenticatedTemplate>
              <MetaDataPage />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Redirect to="/" />
            </UnauthenticatedTemplate>
          </Route>    
          <Route path="/termsofuse">
            <TermsOfUsePage />
          </Route>
          <Route exact path="/">
            <LoginPage />
          </Route>          
        </Switch>        
      </Suspense>
      <Footer />
    </Flex>
  );
}
